// export const BEE_CLIENT_ID = "5b20f6d2-f9d9-46cb-ad8b-fea7eda6640d";
// export const BEE_CLIENT_SECRET =
//   "387ZRxa621eSLWVlkqi5C2km7xp2hOX4Nr5ion5o1QNe18VqWEw";

const PORTAL_LOG_IN_INVOICES = {
  name: "Portal Log In - Invoices",
  value: "<% CustomerSignUpURL_fsFinancialSection||Text: Enter text here %>",
  key: "CustomerSignUpURL_fsFinancialSection"
};

const PORTAL_LINK_INVOICES = {
  name: "Portal Link - Invoices",
  value: "<% CustomerSignInURL_fsFinancialSection||Text: Enter text here %>",
  key: "CustomerSignInURL_fsFinancialSection"
};

export const CONTACTS_MERGE_TAGS = [
  {
    name: "Firstname",
    value: "<% firstName %>",
    key: "firstName"
  },
  {
    name: "Lastname",
    value: "<% lastName %>",
    key: "lastName"
  }
];

export const MERGE_TAGS = [
  {
    name: "--Client Fields--",
    value: "",
    key: "clientFields"
  },
  ...CONTACTS_MERGE_TAGS,
  {
    name: "Emailid",
    value: "<% Email %>",
    key: "mailId"
  },
  {
    name: "Company",
    value: "<% company %>",
    key: "company"
  },
  {
    name: "Phone",
    value: "<% phone %>",
    key: "phone"
  },
  {
    name: "Title",
    value: "<% title %>",
    key: "title"
  },
  {
    name: "City",
    value: "<% city %>",
    key: "city"
  },
  {
    name: "Country",
    value: "<% country %>",
    key: "country"
  },
  {
    name: "County",
    value: "<% county %>",
    key: "county"
  },
  {
    name: "State",
    value: "<% state %>",
    key: "state"
  },
  {
    name: "Rep name",
    value: "<% repName %>",
    key: "repName"
  },
  {
    name: "Time trade link",
    value: "<% timeTradeLink %>",
    key: "timeTradeLink"
  }
];

export const WORKFLOW_MERGE_TAGS = [
  {
    name: "--Workflow Fields--",
    value: "",
    key: "workflowFields"
  },
  {
    name: "Webhook Data",
    value: "<% webhookData %>",
    key: "webhookData"
  },
  {
    name: "Form Data",
    value: "<% formData %>",
    key: "formData"
  },
  {
    name: "Form Name",
    value: "<% formName %>",
    key: "formName"
  },
  {
    name: "Enrolled Campaign Name",
    value: "<% enrolledCampaignName %>",
    key: "enrolledCampaignName"
  }
];

export const FORM_SUBMISSION_MERGE_TAGS = [
  {
    name: "--Form Submission Fields--",
    value: "",
    key: "formSubmission"
  },
  {
    name: "Form Data",
    value: "<% formData %>",
    key: "formData"
  },
  {
    name: "Form Name",
    value: "<% formName %>",
    key: "formName"
  }
];

export const WEBHOOK_MERGE_TAGS = [
  {
    name: "--Webhook Fields--",
    value: "",
    key: "webhook"
  },
  {
    name: "Webhook Data",
    value: "<% webhookData %>",
    key: "webhookData"
  }
];

export const SUBSCRIPTION_MERGE_TAGS = [
  {
    name: "--Subscription Fields--",
    value: "",
    key: "subscriptionFields"
  },
  {
    name: "Plan name",
    value: "<% subscription_Name %>",
    key: "subscription_Name"
  },
  {
    name: "Subscription Number",
    value: "<% subscription_Number %>",
    key: "subscription_Number"
  },
  {
    name: "Quantity",
    value: "<% subscription_Quantity %>",
    key: "subscription_Quantity"
  },
  {
    name: "Subscription created date",
    value: "<% subscription_CreatedOn %>",
    key: "subscription_CreatedOn"
  },
  {
    name: "Subscription start date",
    value: "<% subscription_StartDate %>",
    key: "subscription_StartDate"
  },

  {
    name: "Subscription end date",
    value: "<% subscription_SubscriptionEndDate %>",
    key: "subscription_SubscriptionEndDate"
  },
  {
    name: "Trial start date",
    value: "<% subscription_TrialStartDate %>",
    key: "subscription_TrialStartDate"
  },
  {
    name: "Trial end date",
    value: "<% subscription_TrialEndDate %>",
    key: "subscription_TrialEndDate"
  },
  {
    name: "Next billing date",
    value: "<% subscription_NextBillingAt %>",
    key: "subscription_NextBillingAt"
  },
  {
    name: "Renewal date",
    value: "<% subscription_RenewalDate %>",
    key: "subscription_RenewalDate"
  },
  {
    name: "Renewal Link",
    value: "<% subscription_RenewalLink||Text: Enter text here %>",
    key: "subscription_RenewalLink"
  },
  {
    name: "Current cycle/Total Billing cycles",
    value: "<% subscription_BillingCycle %>",
    key: "subscription_BillingCycle"
  },
  {
    name: "Cancelled date",
    value: "<% subscription_CancelledDate %>",
    key: "subscription_CancelledDate"
  },
  {
    name: "Payment Summary",
    value: "<% subscription_PaymentSummary %>",
    key: "subscription_PaymentSummary"
  },
  {
    name: "Total Billing Cycles",
    value: "<% subscription_BillingCycles %>",
    key: "subscription_BillingCycles"
  },
  {
    name: "Remaining Billing Cycles",
    value: "<% subscription_RemainingBillingCycles %>",
    key: "subscription_RemainingBillingCycles"
  },
  {
    name: "Refund Summary",
    value: "<% subscription_RefundSummary %>",
    key: "subscription_RefundSummary"
  },
  {
    name: "Customer Portal Link",
    value: "<% CustomerSignUpURL||Text: Enter text here %>",
    key: "CustomerSignUpURL"
  },
  {
    name: "Gifter Name",
    value: "<% subscription_GifterName %>",
    key: "subscription_GifterName"
  },
  {
    name: "Gifter Email",
    value: "<% subscription_GifterEmail %>",
    key: "subscription_GifterEmail"
  },
  {
    name: "Gifter Phone",
    value: "<% subscription_GifterPhone %>",
    key: "subscription_GifterPhone"
  },
  {
    name: "Next Billing Cycle Amount",
    value: "<% subscription_nextBillingCycleAmount %>",
    key: "subscription_nextBillingCycleAmount"
  },
  {
    name: "Next Renewal Amount",
    value: "<% subscription_nextRenewalAmount %>",
    key: "subscription_nextRenewalAmount"
  },
  {
    name: "End Action",
    value: "<% subscription_endAction %>",
    key: "subscription_endAction"
  }
];

export const ADMATERIAL_MERGE_TAGS = [
  {
    name: "--Production Fields--",
    value: "",
    key: "order_prodFields"
  },
  {
    name: "Contract ID",
    value: "<% order_orderId %>",
    key: "order_orderId"
  },
  {
    name: "Proposal Number",
    value: "<% proposal_number %>",
    key: "proposal_number"
  },
  {
    name: "Proposal Total",
    value: "<% proposal_total %>",
    key: "proposal_total"
  },
  {
    name: "Issue Date",
    value: "<% order_startDate %>",
    key: "order_startDate"
  },
  {
    name: "AdMaterial Deadline Date",
    value: "<% order_DeadlineDate %>",
    key: "order_DeadlineDate"
  },
  {
    name: "Order Job Status",
    value: "<% order_jobStatus %>",
    key: "order_jobStatus"
  },
  {
    name: "Ad Number",
    value: "<% order_AdNumber %>",
    key: "order_AdNumber"
  },
  {
    name: "Zone - Product",
    value: "<% order_Product %>",
    key: "order_Product"
  },
  {
    name: "Issue",
    value: "<% order_IssueName %>",
    key: "order_IssueName"
  },
  {
    name: "Year",
    value: "<% order_Year %>",
    key: "order_Year"
  },
  {
    name: "Ad Size",
    value: "<% order_AdSize %>",
    key: "order_AdSize"
  },
  {
    name: "Rate Card",
    value: "<% order_RateCard %>",
    key: "order_RateCard"
  },
  {
    name: "Ad Color",
    value: "<% order_AdColor %>",
    key: "order_AdColor"
  },
  {
    name: "Premium Position",
    value: "<% order_AdPosition %>",
    key: "order_AdPosition"
  },
  {
    name: "Section",
    value: "<% order_AdSection %>",
    key: "order_AdSection"
  },
  {
    name: "Net",
    value: "<% order_Net %>",
    key: "order_Net"
  },
  {
    name: "Production Charge",
    value: "<% order_ProdCharge %>",
    key: "order_ProdCharge"
  },
  {
    name: "Discounts",
    value: "<% order_Discount %>",
    key: "order_Discount"
  },
  {
    name: "Date Booked",
    value: "<% order_dateAdded %>",
    key: "order_dateAdded"
  },
  {
    name: "Rep(s)",
    value: "<% order_RepIDs %>",
    key: "order_RepIDs"
  },
  {
    name: "Ad Start Date",
    value: "<% order_startDate %>",
    key: "order_startDate"
  },
  {
    name: "Ad End Date",
    value: "<% order_endDate %>",
    key: "order_endDate"
  },
  {
    name: "Last Placed Ad",
    value: "<% last_placedAd %>",
    key: "last_placedAd"
  },
  {
    name: "Rep Email(s)",
    value: "<% order_RepEmails %>",
    key: "order_RepEmails"
  },
  {
    name: "Orders Summary",
    value: "<% order_ordersSummary %>",
    key: "order_ordersSummary"
  },
  {
    name: "Current Stage",
    value: "<% order_Stage %>",
    key: "order_Stage"
  },
  {
    name: "Previous Stage",
    value: "<% order_PreviousStage %>",
    key: "order_PreviousStage"
  },
  {
    name: "Customer SignUp URL",
    value: "<% CustomerSignUpURL||Text: Enter text here %>",
    key: "CustomerSignUpURL"
  },
  {
    name: "Production Due Date",
    value: "<% prod_OrderDueDate %>",
    key: "prod_OrderDueDate"
  },
  {
    name: "Project Completed Date",
    value: "<% project_CompletedDate %>",
    key: "project_CompletedDate"
  },
  {
    name: "Project Start Date",
    value: "<% project_StartDate %>",
    key: "project_StartDate"
  },
  {
    name: "Order Deleted Reason",
    value: "<% order_deleted_reason %>",
    key: "order_deleted_reason"
  },
  {
    name: "Mailing List Close Date",
    value: "<% mailing_list_close_date %>",
    key: "mailing_list_close_date"
  },
  {
    name: "Space Reservation Deadline",
    value: "<% space_reservation_deadline %>",
    key: "space_reservation_deadline"
  },
  {
    name: "Files Shipped To Printer",
    value: "<% files_shipped_to_printer %>",
    key: "files_shipped_to_printer"
  },
  {
    name: "Proofs Arrive From Printer",
    value: "<% proofs_arrive_from_printer %>",
    key: "proofs_arrive_from_printer"
  },
  {
    name: "Periodicals Shipped From Printer",
    value: "<% periodicals_shipped_from_printer %>",
    key: "periodicals_shipped_from_printer"
  },
  {
    name: "Accounting Close Date",
    value: "<% accounting_close_date %>",
    key: "accounting_close_date"
  },
  {
    name: "On Sale Date",
    value: "<% on_sale_date %>",
    key: "on_sale_date"
  }
];

export const AD_APPROVAL_MERGE_TAGS = [
  {
    name: "Production Approval Link",
    value: "<% ProductionApprovalLink||Text: Enter text here %>",
    key: "ProductionApprovalLink"
  },
  {
    name: "Ad Approval Reminder Count",
    value: "<% add_approval_reminder_count %>",
    key: "add_approval_reminder_count"
  }
];

export const LATENOTICE_MERGE_TAGS = [
  {
    name: "--Late Notice Fields--",
    value: "",
    key: "LateNoticeFields"
  },
  {
    name: "Invoice ID",
    value: "<% Invoice_ID %>",
    key: "Invoice_ID"
  },
  {
    name: "Invoice Number",
    value: "<% Invoice_Number %>",
    key: "Invoice_Number"
  },
  {
    name: "Invoice Total Amount",
    value: "<% Invoice_Total_Amount %>",
    key: "Invoice_Total_Amount"
  },
  {
    name: "Due Date",
    value: "<% Due_Date %>",
    key: "Due_Date"
  },
  {
    name: "Amount Paid",
    value: "<% Amount_Paid %>",
    key: "Amount_Paid"
  },
  {
    name: "Amount Due",
    value: "<% Amount_Due %>",
    key: "Amount_Due"
  },
  {
    name: "Portal Log In - Orders",
    value: "<% CustomerSignUpURL_fsOrders||Text: Enter text here %>",
    key: "CustomerSignUpURL_fsOrders"
  },
  PORTAL_LOG_IN_INVOICES,
  {
    name: "Portal Log In - Payment Bill Plan",
    value: "<% CustomerSignUpURL_fsPaymentInstallments||Text: Enter text here %>",
    key: "CustomerSignUpURL_fsPaymentInstallments"
  },
  {
    name: "Portal Log In - Payments",
    value: "<% CustomerSignUpURL_fsPaymentHistory||Text: Enter text here %>",
    key: "CustomerSignUpURL_fsPaymentHistory"
  },
  {
    name: "Portal Link - Orders",
    value: "<% CustomerSignInURL_fsOrders||Text: Enter text here %>",
    key: "CustomerSignInURL_fsOrders"
  },
  PORTAL_LINK_INVOICES,
  {
    name: "Portal Link - Payment Plan Bill",
    value: "<% CustomerSignInURL_fsPaymentInstallments||Text: Enter text here %>",
    key: "CustomerSignInURL_fsPaymentInstallments"
  },
  {
    name: "Portal Link - Payments",
    value: "<% CustomerSignInURL_fsPaymentHistory||Text: Enter text here %>",
    key: "CustomerSignInURL_fsPaymentHistory"
  },
  {
    name: "Invoice PDF",
    value: "<% LinkInvoicePDF||Text: Enter text here %>",
    key: "LinkInvoicePDF"
  },
  {
    name: "Invoice Date",
    value: "<% Invoice_Date %>",
    key: "Invoice_Date"
  },
  {
    name: "Full Name",
    value: "<% Full_Name %>",
    key: "Full_Name"
  },
  {
    name: "Address Block",
    value: "<% Address_Block %>",
    key: "Address_Block"
  }
];

export const REP_NOTIFICATION_MERGE_TAGS = [
  {
    name: "--Rep Notification Fields--",
    value: "",
    key: "order_repNotificationFields"
  },
  {
    name: "Stage Name",
    value: "<% order_StageName %>",
    key: "order_StageName"
  },
  {
    name: "Pickup From Description",
    value: "<% order_PickupFromDesc %>",
    key: "order_PickupFromDesc"
  },
  {
    name: "From Name",
    value: "<% order_FromName %>",
    key: "order_FromName"
  },
  {
    name: "Login Rep",
    value: "<% order_LoggedInRep %>",
    key: "order_LoggedInRep"
  },
  {
    name: "Designer Number",
    value: "<% order_DesignerNumber %>",
    key: "order_DesignerNumber"
  },
  {
    name: "Job Jacket Page Link",
    value: "<% JobJacketPageLink||Text: Enter text here %>",
    key: "JobJacketPageLink"
  }
];

export const PROD_CONTACT_MERGE_TAGS = [
  {
    name: "--Production Contact Fields--",
    value: "",
    key: "prodContactTags"
  },
  {
    name: "Production Full Name",
    value: "<% prodContact_name %>",
    key: "prodContact_name"
  },
  {
    name: "Production Title",
    value: "<% prodContact_title %>",
    key: "prodContact_title"
  },
  {
    name: "Production Phone",
    value: "<% prodContact_phone %>",
    key: "prodContact_phone"
  },
  {
    name: "Production Email",
    value: "<% prodContact_email %>",
    key: "prodContact_email"
  },
  {
    name: "Primary Contact Company",
    value: "<% primaryContact_company %>",
    key: "primaryContact_company"
  }
];

export const SUMMARY_NOTIFICATION_MERGE_TAGS = [
  {
    name: "--Summary Notification Fields--",
    value: "",
    key: "summaryNotificationTags"
  },
  {
    name: "Today's Contracts",
    value: "<% sn_Today_Contracts %>",
    key: "sn_Today_Contracts"
  },
  {
    name: "Today's Activity",
    value: "<% sn_Today_Activity %>",
    key: "sn_Today_Activity"
  },
  {
    name: "Today's Pipeline Visibility",
    value: "<% sn_Today_PipelineVisibility %>",
    key: "sn_Today_PipelineVisibility"
  },
  {
    name: "Today's Activity by Sales Person",
    value: "<% sn_Today_ActivityBySalesPerson %>",
    key: "sn_Today_ActivityBySalesPerson"
  },
  {
    name: "Today's Proposals",
    value: "<% sn_Today_Proposals %>",
    key: "sn_Today_Proposals"
  },
  {
    name: "Today's Meetings",
    value: "<% sn_Today_Meetings %>",
    key: "sn_Today_Meetings"
  },
  {
    name: "Week's Contracts",
    value: "<% sn_Week_Contracts %>",
    key: "sn_Week_Contracts"
  },
  {
    name: "Week's Activity",
    value: "<% sn_Week_Activity %>",
    key: "sn_Week_Activity"
  },
  {
    name: "Week's Pipeline Visibility",
    value: "<% sn_Week_PipelineVisibility %>",
    key: "sn_Week_PipelineVisibility"
  },
  {
    name: "Week's Activity by Sales Person",
    value: "<% sn_Week_ActivityBySalesPerson %>",
    key: "sn_Week_ActivityBySalesPerson"
  },
  {
    name: "Week's Proposals",
    value: "<% sn_Week_Proposals %>",
    key: "sn_Week_Proposals"
  },
  {
    name: "Week's Meetings",
    value: "<% sn_Week_Meetings %>",
    key: "sn_Week_Meetings"
  },
  {
    name: "Month's Contracts",
    value: "<% sn_Month_Contracts %>",
    key: "sn_Month_Contracts"
  },
  {
    name: "Month's Activity",
    value: "<% sn_Month_Activity %>",
    key: "sn_Month_Activity"
  },
  {
    name: "Month's Pipeline Visibility",
    value: "<% sn_Month_PipelineVisibility %>",
    key: "sn_Month_PipelineVisibility"
  },
  {
    name: "Month's Activity by Sales Person",
    value: "<% sn_Month_ActivityBySalesPerson %>",
    key: "sn_Month_ActivityBySalesPerson"
  },
  {
    name: "Month's Proposals",
    value: "<% sn_Month_Proposals %>",
    key: "sn_Month_Proposals"
  },
  {
    name: "Month's Meetings",
    value: "<% sn_Month_Meetings %>",
    key: "sn_Month_Meetings"
  },
  {
    name: "Quarter's Contracts",
    value: "<% sn_Quarter_Contracts %>",
    key: "sn_Quarter_Contracts"
  },
  {
    name: "Quarter's Activity",
    value: "<% sn_Quarter_Activity %>",
    key: "sn_Quarter_Activity"
  },
  {
    name: "Quarter's Pipeline Visibility",
    value: "<% sn_Quarter_PipelineVisibility %>",
    key: "sn_Quarter_PipelineVisibility"
  },
  {
    name: "Quarter's Activity by Sales Person",
    value: "<% sn_Quarter_ActivityBySalesPerson %>",
    key: "sn_Quarter_ActivityBySalesPerson"
  },
  {
    name: "Quarter's Proposals",
    value: "<% sn_Quarter_Proposals %>",
    key: "sn_Quarter_Proposals"
  },
  {
    name: "Quarter's Meetings",
    value: "<% sn_Quarter_Meetings %>",
    key: "sn_Quarter_Meetings"
  }
];

export const PROD_NOTIFICATION_MERGE_TAGS = [
  {
    name: "--Production Notification Fields--",
    value: "",
    key: "prod_NotificationFields"
  },
  {
    name: "Assigned Rep",
    value: "<% notification_AssignedTo %>",
    key: "notification_AssignedTo"
  },
  {
    name: "Partner Reps",
    value: "<% notification_PartnerReps %>",
    key: "notification_PartnerReps"
  },
  {
    name: "Production Priority",
    value: "<% notification_Priority %>",
    key: "notification_Priority"
  },
  {
    name: "Assigned Designer",
    value: "<% notification_Designer %>",
    key: "notification_Designer"
  },
  {
    name: "File Name",
    value: "<% notification_File_Name %>",
    key: "notification_File_Name"
  },
  {
    name: "PickUp Form",
    value: "<% notification_PickUp %>",
    key: "notification_PickUp"
  },
  {
    name: "Production Note",
    value: "<% notification_Note %>",
    key: "notification_Note"
  },
  {
    name: "PickUp Form Description",
    value: "<% Pickup_Contract_Description %>",
    key: "Pickup_Contract_Description"
  },
  {
    name: "Pickup Contract Link",
    value: "<% Pickup_Contract_Link %>",
    key: "Pickup_Contract_Link"
  },
  {
    name: "Customer Note",
    value: "<% customer_note %>",
    key: "customer_note"
  }
];

export const RSS_FEED_MERGETAGS = [
  {
    name: "--RSS Feed Fields--",
    value: "",
    key: "RSSFeed"
  },
  {
    name: "RSS Channel Title",
    value: "<% RSS_channelTitle %>",
    key: "RSS_channelTitle"
  },
  {
    name: "RSS Title",
    value: "<% RSS_title %>",
    key: "RSS_title"
  },
  {
    name: "RSS Description",
    value: "<% RSS_description %>",
    key: "RSS_description"
  },
  {
    name: "RSS PubDate",
    value: "<% RSS_pubDate %>",
    key: "RSS_pubDate"
  },
  {
    name: "RSS Link",
    value: "<% RSS_link %>",
    key: "RSS_link"
  },
  {
    name: "RSS guid",
    value: "<% RSS_guid %>",
    key: "RSS_guid"
  },
  {
    name: "RSS Author",
    value: "<% RSS_author %>",
    key: "RSS_author"
  },
  {
    name: "RSS Enclosure",
    value: "<% RSS_enclosure %>",
    key: "RSS_enclosure"
  }
];

export const RSS_FEED_CHANNEL_MERGETAGS = [
  {
    name: "RSS Channel Title",
    value: "<% RSS_channelTitle %>",
    key: "RSS_channelTitle"
  },
  {
    name: "RSS Title",
    value: "<% RSS_title %>",
    key: "RSS_title"
  }
];

export const COMMON_AUTOMATION_MERGE_TAGS = [
  {
    name: "--Automation--",
    value: "",
    key: "automationTags"
  },
  {
    name: "Schedule Name",
    value: "<% schedule_name %>",
    key: "schedule_name"
  }
];

export const COMMON_AUTOMATION_CONTACTS_MERGE_TAGS = [
  {
    name: "Rep First Name",
    value: "<% rep_first_name %>",
    key: "rep_first_name"
  },
  {
    name: "Rep Last Name",
    value: "<% rep_last_name %>",
    key: "rep_last_name"
  }
];

export const INVOICE_SUMMARY_MERGE_TAG = [
  ...COMMON_AUTOMATION_MERGE_TAGS,
  {
    name: "Invoice Summary",
    value: "<% automated_invoice_summary %>",
    key: "automated_invoice_summary"
  }
];

export const PREPAY_SUMMARY_MERGE_TAG = [
  ...COMMON_AUTOMATION_MERGE_TAGS,
  {
    name: "Prepay Summary",
    value: "<% automated_prepay_summary %>",
    key: "automated_prepay_summary"
  }
];

export const BATCH_BILLING_SUMMARY_MERGE_TAG = [
  ...COMMON_AUTOMATION_MERGE_TAGS,
  {
    name: "Batch Billing Summary",
    value: "<% automated_batchBilling_summary %>",
    key: "automated_batchBilling_summary"
  }
];

export const AUTOMATE_SALES_MODULE_REPORTS_MERGE_TAG = [
  ...COMMON_AUTOMATION_MERGE_TAGS,
  ...COMMON_AUTOMATION_CONTACTS_MERGE_TAGS,
  {
    name: "Created By",
    value: "<% CreatedBy %>",
    key: "CreatedBy"
  },
  {
    name: "Sales Module Report",
    value: "<% sales_module_reports_url||Text: Enter text here  %>",
    key: "sales_module_reports_url"
  }
];

export const AUTOMATE_LATE_INVOICE_NOTICES_MERGE_TAGS = [
  ...COMMON_AUTOMATION_MERGE_TAGS,
  ...COMMON_AUTOMATION_CONTACTS_MERGE_TAGS,
  {
    name: "Late Invoice Notices Summary",
    value: "<% automated_late_invoice_notices_summary %>",
    key: "automated_late_invoice_notices_summary"
  },
  {
    name: "Company Name",
    value: "<% company_name %>",
    key: "company_name"
  },
  {
    name: "Billing Company Name",
    value: "<% billing_company_name %>",
    key: "billing_company_name"
  },
  {
    name: "Billing First Name",
    value: "<% billing_first_name %>",
    key: "billing_first_name"
  },
  {
    name: "Billing Full Name",
    value: "<% billing_full_name %>",
    key: "billing_full_name"
  },
  PORTAL_LOG_IN_INVOICES,
  PORTAL_LINK_INVOICES
];
